#resource-table {
  max-height: 60vh;
  display: block !important;
  overflow: auto !important;
}

#resource-table::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

#resource-table::-webkit-scrollbar-thumb {
  background-color: darkgrey;
}
